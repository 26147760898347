.cursor {
  display: inline-block;
  width: 2px;
  height: 15px;
  background-color: white;
  animation: blink 0.5s infinite;
}

@keyframes blink {
  50% {
    opacity: 0;
  }
}

