@font-face {
  font-family: 'jetbrains-regular';
  src: url('/fonts/jetbrains-mono-font/JetbrainsMonoRegular-RpvmM.ttf') format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'jetbrains-bold';
  src: url('/fonts/jetbrains-mono-font/JetbrainsMonoBold-51Xez.ttf') format("truetype");
  font-weight: normal;
  font-style: normal;
}

